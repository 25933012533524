// theme constant
export const gridSpacing = 3;
export const drawerWidth = 260;
export const appDrawerWidth = 320;
export const borderRadius = 3;

export const taskStatus = ['Assigned', 'Ongoing', 'Completed', 'Cancelled'];
export const taskType = ['Onsite', 'Offsite'];
export const gstType = [' Unregistered', 'Registered - Regular', 'Registered- Composition', ' Consumer'];
export const productStatus = ['Active', 'InActive'];
export const partStatus = ['Active', 'InActive'];
export const userRole = [
    'Owner',
    'Admin',
    'Sales Executive',
    'Office Staff',
    'Store Manager',
    'Site Executive',
    'Maintenance Engineer',
    'Service Engineer',
    'Customer'
];
export const userType = ['Internal', 'External'];
export const quotationType = [
    { value: 0, title: 'New' },
    { value: 1, title: 'Revised' }
];
export const materialStatus = ['Assigned', 'Handoverd', ' Used', ' Returned', 'Damaged', 'Lost'];
export const customerPaymentMode = ['Cash', 'UPI', 'Credit/Debit Card', 'Bank Transfer', 'Cheque'];
export const UserStatus = ['Active', 'InActive'];
export const expenseLocation = ['Miraj', 'Athani'];
export const maintenanceWarrantyType = ['In Warranty', 'Out Of Warranty'];
