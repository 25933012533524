// assets
import {
    IconDashboard,
    IconUsers,
    IconCalendarTime,
    IconBed,
    IconFileInvoice,
    IconReportAnalytics,
    IconSettings,
    IconCertificate,
    IconBrandMastercard,
    IconNotes,
    IconReportMoney,
    IconAccessible
} from '@tabler/icons';

// constant
const icons = {
    IconDashboard,
    IconUsers,
    IconCalendarTime,
    IconBed,
    IconFileInvoice,
    IconReportAnalytics,
    IconSettings,
    IconCertificate,
    IconBrandMastercard,
    IconNotes,
    IconReportMoney,
    IconAccessible
};

const adminMenuItems = [
    {
        id: 'admin-main-pages',
        type: 'group',
        children: [
            {
                id: 'dashboard',
                title: 'Dashboard',
                type: 'item',
                icon: icons.IconDashboard,
                url: '/dashboard',
                breadcrumbs: false
            },

            {
                id: 'product',
                title: 'Master',
                type: 'collapse',
                icon: icons.IconReportAnalytics,
                breadcrumbs: false,
                children: [
                    {
                        id: 'customer-category-master',
                        title: 'Customer Categories',
                        type: 'item',
                        // icon: icons.IconReportAnalytics,
                        url: '/customer-category-master',
                        breadcrumbs: false
                    },

                    {
                        id: 'customer',
                        title: 'Customers',
                        type: 'item',
                        // icon: icons.IconUsers,
                        url: '/customer',
                        breadcrumbs: false
                    },
                    {
                        id: 'vendor',
                        title: 'Vendors',
                        type: 'item',
                        // icon: icons.IconReportAnalytics,
                        url: '/vendor',
                        breadcrumbs: false
                    },
                    {
                        id: 'product-category',
                        title: 'Product Categories',
                        type: 'item',
                        //icon: icons.IconFileInvoice,
                        url: '/product-category',
                        breadcrumbs: false
                    },
                    {
                        id: 'product',
                        title: 'Products',
                        type: 'item',
                        // icon: icons.IconReportAnalytics,
                        url: '/product',
                        breadcrumbs: false
                    },

                    {
                        id: 'part-category-master',
                        title: 'Part Categories',
                        type: 'item',
                        // icon: icons.IconReportAnalytics,
                        url: '/part-category-master',
                        breadcrumbs: false
                    },
                    {
                        id: 'part-master',
                        title: 'Parts',
                        type: 'item',
                        // icon: icons.IconReportAnalytics,
                        url: '/part-master',
                        breadcrumbs: false
                    },

                    {
                        id: 'service',
                        title: 'Services',
                        type: 'item',
                        url: '/service',
                        breadcrumbs: false
                    },
                    // {
                    //     id: 'account',
                    //     title: 'Bank Account',
                    //     type: 'item',
                    //     url: '/account',
                    //     breadcrumbs: false
                    // },

                    {
                        id: 'expenses-category-master',
                        title: 'Expenses Categories',
                        type: 'item',
                        url: '/expenses-category-master',
                        breadcrumbs: false
                    }
                    // {
                    //     id: 'location-master',
                    //     title: 'Locations',
                    //     type: 'item',
                    //     url: '/location-master',
                    //     breadcrumbs: false
                    // }
                ]
            },

            {
                id: 'purchase-records',
                title: 'Purchase Records',
                type: 'item',
                icon: icons.IconBrandMastercard,
                url: '/purchase-records',
                breadcrumbs: false
            },
            {
                id: 'quotation',
                title: 'Quotation',
                type: 'item',
                icon: icons.IconNotes,
                url: '/quotation',
                breadcrumbs: false
            },
            {
                id: 'sales-invoice',
                title: 'Sales Invoice',
                type: 'item',
                icon: icons.IconReportMoney,
                url: '/sales-invoice',
                breadcrumbs: false
            },
            // {
            //     id: 'service-invoice',
            //     title: 'Service Invoice',
            //     type: 'item',
            //     icon: icons.IconReportMoney,
            //     url: '/service-invoice',
            //     breadcrumbs: false
            // },

            {
                id: 'master',
                title: 'Inquiries',
                type: 'collapse',
                icon: icons.IconReportAnalytics,
                breadcrumbs: false,
                children: [
                    {
                        id: 'sales-inquiry',
                        title: 'Sales Inquiries',
                        type: 'item',
                        url: '/sales-inquiry',
                        breadcrumbs: false
                    },
                    {
                        id: 'maintenance-inquiry',
                        title: 'Maintenance Inquiries',
                        type: 'item',
                        url: '/maintenance-inquiry',
                        breadcrumbs: false
                    }
                ]
            },
            {
                id: 'expenses',
                title: 'Expenses',
                type: 'item',
                icon: icons.IconBrandMastercard,
                url: '/expenses',
                breadcrumbs: false
            },
            {
                id: 'customer-payment',
                title: 'Customer Payments',
                type: 'item',
                icon: icons.IconReportMoney,
                url: '/customer-payment',
                breadcrumbs: false
            },
            {
                id: 'vendor-payment',
                title: 'Vendor Payments',
                type: 'item',
                icon: icons.IconReportAnalytics,
                url: '/vendor-payment',
                breadcrumbs: false
            },

            {
                id: 'reports',
                title: 'Reports',
                type: 'item',
                icon: icons.IconReportAnalytics,
                url: '/reports',
                breadcrumbs: false
            },
            {
                id: 'store',
                title: 'Material Store',
                type: 'item',
                icon: icons.IconBrandMastercard,
                url: '/store',
                breadcrumbs: false
            },
            {
                id: 'advance',
                icon: icons.IconBrandMastercard,
                title: 'Advance Payments',
                type: 'item',
                url: '/advance',
                breadcrumbs: false
            }
        ]
    },

    {
        id: 'main-settings',
        type: 'group',
        children: [
            {
                id: 'setting-pages',
                title: 'Settings',
                type: 'collapse',
                icon: icons.IconSettings,
                breadcrumbs: false,
                children: [
                    {
                        id: 'company',
                        title: 'Company Details',
                        type: 'item',
                        url: '/company',
                        breadcrumbs: false
                    },
                    {
                        id: 'user',
                        title: 'Users',
                        type: 'item',
                        //icon: icons.IconUsers,
                        url: '/user',
                        breadcrumbs: false
                    }
                ]
            }
        ]
    }
];

export default adminMenuItems;
