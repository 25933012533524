import { useRoutes } from 'react-router-dom';
import { useReducer, useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { lazy } from 'react';

// project imports
import MainLayout from 'layout/MainLayout';
import MinimalLayout from 'layout/MinimalLayout';
import Loadable from 'ui-component/Loadable';
import config from 'config';

import Dashboard from '../views/dashboard/index.jsx';

// login routing
const Login = Loadable(lazy(() => import('views/login')));
const ForgotPassword = Loadable(lazy(() => import('views/login/forgot-password')));

// master routing
const CustomerCategory = Loadable(lazy(() => import('views/masters/customer-category-master/index.jsx')));
const Customer = Loadable(lazy(() => import('views/masters/customer/index.jsx')));
const Vendor = Loadable(lazy(() => import('views/masters/vendor/index.jsx')));
const ProductCategory = Loadable(lazy(() => import('views/masters/product-category/index.jsx')));
const Product = Loadable(lazy(() => import('views/masters/product/index.jsx')));
const PartCategory = Loadable(lazy(() => import('views/masters/part-category-master/index.jsx')));
const PartList = Loadable(lazy(() => import('views/masters/part-master/index.jsx')));
const User = Loadable(lazy(() => import('views/masters/user/index.jsx')));
const Account = Loadable(lazy(() => import('views/masters/account/index.jsx')));
const CompanyList = Loadable(lazy(() => import('views/masters/company/index.jsx')));
const ExpenseCaterogy = Loadable(lazy(() => import('views/masters/expense-category-master/index.jsx')));
const Service = Loadable(lazy(() => import('views/masters/service-master')));
const Location = Loadable(lazy(() => import('views/masters/location-master/index.jsx')));

//invoice import

//const PurchaseRecord = Loadable(lazy(() => import('views/purchase-record')));

//Inquiry
const SalesInquiry = Loadable(lazy(() => import('views/inquiries/sales-inquiry/index.jsx')));
const InquiryDetails = Loadable(lazy(() => import('views/inquiries/sales-inquiry/inquiry-details/index.jsx')));
const SalesInquiryForm = Loadable(lazy(() => import('views/inquiries/sales-inquiry/SalesInquiryForm.jsx')));
const MaintenanceInquiry = Loadable(lazy(() => import('views/inquiries/maintenance-inquiry/index.jsx')));
const MaintenanceInquiryForm = Loadable(lazy(() => import('views/inquiries/maintenance-inquiry/MaintenanceInquiryForm.jsx')));
const MaintenanceInquiryDetials = Loadable(lazy(() => import('views/inquiries/maintenance-inquiry/maintenance-inquiry-details/index.jsx')));
const AdvanceToEmployee = Loadable(
    lazy(() => import('views/inquiries/maintenance-inquiry/maintenance-inquiry-details/advance-to-employee/index.jsx'))
);
const AdvanceReturnByEmployee = Loadable(
    lazy(() => import('views/inquiries/maintenance-inquiry/maintenance-inquiry-details/advance-return-by-employee/index.jsx'))
);
const TaskData = Loadable(lazy(() => import('views/inquiries/maintenance-inquiry/maintenance-inquiry-details/task/index.jsx')));
const TaskDetails = Loadable(
    lazy(() => import('views/inquiries/maintenance-inquiry/maintenance-inquiry-details/task/TaskDetails/index.jsx'))
);
const PartInwardRecord = Loadable(
    lazy(() => import('views/inquiries/maintenance-inquiry/maintenance-inquiry-details/part-inward-record/index.jsx'))
);
const PartOutwardRecord = Loadable(
    lazy(() => import('views/inquiries/maintenance-inquiry/maintenance-inquiry-details/part-outword-record/index.jsx'))
);
const MachineInwardRecord = Loadable(
    lazy(() => import('views/inquiries/maintenance-inquiry/maintenance-inquiry-details/machine-inward-record/index.jsx'))
);
const MachineInwardRecordForm = Loadable(
    lazy(() => import('views/inquiries/maintenance-inquiry/maintenance-inquiry-details/machine-inward-record/MachineInwardRecordForm.jsx'))
);
const MachineOutwardRecord = Loadable(
    lazy(() => import('views/inquiries/maintenance-inquiry/maintenance-inquiry-details/machine-outward-record/index.jsx'))
);
const MachineOutwardRecordForm = Loadable(
    lazy(() =>
        import('views/inquiries/maintenance-inquiry/maintenance-inquiry-details/machine-outward-record/MachineOutwardRecordForm.jsx')
    )
);
const Advance = Loadable(lazy(() => import('views/advance/index.jsx')));

//Purchase Records

const PurchaseRecords = Loadable(lazy(() => import('views/purchase-records/index')));
const PurchaseRecordForm = Loadable(lazy(() => import('views/purchase-records/PurchaseRecordForm')));

//Quotation
const Quotation = Loadable(lazy(() => import('views/quotation/index')));
const QuotationForm = Loadable(lazy(() => import('views/quotation/QuotationForm')));

//Sales Invoice
const SalesInvoice = Loadable(lazy(() => import('views/general-sales-invoice/index')));
const SalesInvoiceForm = Loadable(lazy(() => import('views/general-sales-invoice/GeneralSalesInvoiceForm')));
// //Service Invoice
// const ServiceInvoice = Loadable(lazy(() => import('views/service-invoice/index.jsx')));
// const ServiceInvoiceForm = Loadable(lazy(() => import('views/service-invoice/ServiceInvoiceForm.jsx')));
//Expenses
const Expenses = Loadable(lazy(() => import('views/expenses/index.jsx')));
//Customer Payment
const CustomerPayment = Loadable(lazy(() => import('views/customer-payment/index.jsx')));
//Vendor Payment
const VendorPayment = Loadable(lazy(() => import('views/vendor-payment/index.jsx')));
//Store
const StoreData = Loadable(lazy(() => import('views/store/index.jsx')));

//Reports
const Reports = Loadable(lazy(() => import('views/reports/index.jsx')));
// ==============================|| ROUTING RENDER ||============================== //

export default function ThemeRoutes() {
    const { userId, userName, role, displayRole } = useSelector((state) => state.userReducer);
    //const userId = 1;
    const AuthenticationRoutes = {
        path: '/',
        element: <MinimalLayout />,
        children: [
            {
                path: '/login',
                element: <Login />
            },
            {
                path: '/forgot-password',
                element: <ForgotPassword />
            }
        ]
    };

    const MainRoutes = {
        path: '/',
        element: <MainLayout />,
        children: [
            {
                path: '/',
                element: userId > 0 ? <Dashboard /> : <Navigate to="/login" />
            },
            {
                path: '/dashboard',
                element: userId > 0 ? <Dashboard /> : <Navigate to="/login" />
            },
            {
                path: '/customer',
                element: userId > 0 ? <Customer /> : <Navigate to="/login" />
            },
            {
                path: '/product',
                element: userId > 0 ? <Product /> : <Navigate to="/login" />
            },

            {
                path: '/product-category',
                element: userId > 0 ? <ProductCategory /> : <Navigate to="/login" />
            },
            {
                path: '/vendor',
                element: userId > 0 ? <Vendor /> : <Navigate to="/login" />
            },
            {
                path: '/user',
                element: userId > 0 ? <User /> : <Navigate to="/login" />
            },
            {
                path: '/company',
                element: userId > 0 ? <CompanyList /> : <Navigate to="/login" />
            },
            {
                path: '/account',
                element: userId > 0 ? <Account /> : <Navigate to="/login" />
            },
            {
                path: '/expenses-category-master',
                element: userId > 0 ? <ExpenseCaterogy /> : <Navigate to="/login" />
            },
            {
                path: '/customer-category-master',
                element: userId > 0 ? <CustomerCategory /> : <Navigate to="/login" />
            },
            {
                path: '/part-master',
                element: userId > 0 ? <PartList /> : <Navigate to="/login" />
            },
            {
                path: '/part-category-master',
                element: userId > 0 ? <PartCategory /> : <Navigate to="/login" />
            },
            {
                path: '/service',
                element: userId > 0 ? <Service /> : <Navigate to="/login" />
            },
            {
                path: '/location-master',
                element: userId > 0 ? <Location /> : <Navigate to="/login" />
            },

            {
                path: '/sales-inquiry',
                element: userId > 0 ? <SalesInquiry /> : <Navigate to="/login" />
            },
            {
                path: '/maintenance-inquiry',
                element: userId > 0 ? <MaintenanceInquiry /> : <Navigate to="/login" />
            },
            {
                path: '/inquiry-details',
                element: userId > 0 ? <InquiryDetails /> : <Navigate to="/login" />
            },
            {
                path: '/sales-inquiry-form',
                element: userId > 0 ? <SalesInquiryForm /> : <Navigate to="/login" />
            },
            {
                path: '/maintenance-inquiry-form',
                element: userId > 0 ? <MaintenanceInquiryForm /> : <Navigate to="/login" />
            },
            {
                path: '/maintenance-inquiry-details',
                element: userId > 0 ? <MaintenanceInquiryDetials /> : <Navigate to="/login" />
            },
            {
                path: '/task',
                element: userId > 0 ? <TaskData /> : <Navigate to="/login" />
            },
            {
                path: '/task-details',
                element: userId > 0 ? <TaskDetails /> : <Navigate to="/login" />
            },
            {
                path: '/part-inward-record',
                element: userId > 0 ? <PartInwardRecord /> : <Navigate to="/login" />
            },
            {
                path: '/part-outward-record',
                element: userId > 0 ? <PartOutwardRecord /> : <Navigate to="/login" />
            },
            {
                path: '/machine-inward-record',
                element: userId > 0 ? <MachineInwardRecord /> : <Navigate to="/login" />
            },
            {
                path: '/machine-inward-record-form',
                element: userId > 0 ? <MachineInwardRecordForm /> : <Navigate to="/login" />
            },
            {
                path: '/machine-outward-record',
                element: userId > 0 ? <MachineOutwardRecord /> : <Navigate to="/login" />
            },
            {
                path: '/machine-outward-record-form',
                element: userId > 0 ? <MachineOutwardRecordForm /> : <Navigate to="/login" />
            },
            {
                path: '/advance-to-employee',
                element: userId > 0 ? <AdvanceToEmployee /> : <Navigate to="/login" />
            },
            {
                path: '/advance-return-by-employee',
                element: userId > 0 ? <AdvanceReturnByEmployee /> : <Navigate to="/login" />
            },
            {
                path: '/advance',
                element: userId > 0 ? <Advance /> : <Navigate to="/login" />
            },
            // ------------------------PurchaseRecords------------------------------
            {
                path: '/purchase-records',
                element: userId > 0 ? <PurchaseRecords /> : <Navigate to="/login" />
            },
            {
                path: '/purchase-record-form',
                element: userId > 0 ? <PurchaseRecordForm /> : <Navigate to="/login" />
            },
            // ------------------------Quotations------------------------------
            {
                path: '/quotation',
                element: userId > 0 ? <Quotation /> : <Navigate to="/login" />
            },
            {
                path: '/quotation-form',
                element: userId > 0 ? <QuotationForm /> : <Navigate to="/login" />
            },
            // ------------------------General Sales Invoice------------------------------
            {
                path: '/sales-invoice',
                element: userId > 0 ? <SalesInvoice /> : <Navigate to="/login" />
            },
            {
                path: '/sales-invoice-form',
                element: userId > 0 ? <SalesInvoiceForm /> : <Navigate to="/login" />
            },
            // ------------------------Service Invoice------------------------------
            // {
            //     path: '/service-invoice',
            //     element: userId > 0 ? <ServiceInvoice /> : <Navigate to="/login" />
            // },
            // {
            //     path: '/service-invoice-form',
            //     element: userId > 0 ? <ServiceInvoiceForm /> : <Navigate to="/login" />
            // },
            // ------------------------Expenses------------------------------
            {
                path: '/expenses',
                element: userId > 0 ? <Expenses /> : <Navigate to="/login" />
            },
            // ------------------------Customer Payment------------------------------
            {
                path: '/customer-payment',
                element: userId > 0 ? <CustomerPayment /> : <Navigate to="/login" />
            },
            // ------------------------Vendor Payment------------------------------
            {
                path: '/vendor-payment',
                element: userId > 0 ? <VendorPayment /> : <Navigate to="/login" />
            },
            // ------------------------Material Store------------------------------
            {
                path: '/store',
                element: userId > 0 ? <StoreData /> : <Navigate to="/login" />
            },
            // ------------------------Reports------------------------------
            {
                path: '/reports',
                element: userId > 0 ? <Reports /> : <Navigate to="/login" />
            }
        ]
    };

    return useRoutes([MainRoutes, AuthenticationRoutes], config.basename);
}
